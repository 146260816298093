import React, { useEffect, useState, useRef } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import api from '../../services/api';
import './Matches.css';
import io from 'socket.io-client';

export default function Matches({ history }) {
  const [user, setUser] = useState('');
  const [matches, setMatches] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMessageBeingSent, setIsMessageBeingSent] = useState(false);
  const messagesEndRef = useRef(null);

  const backEndUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3333';

  useEffect(() => {
    const loggedUser = localStorage.getItem('user');

    if (!loggedUser) {
      history.push('/');
      return;
    }

    setUser(loggedUser);
  }, []);

  useEffect(() => {
    // Rolar automaticamente para a mensagem mais recente após renderização
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (!user) return;

    const loadMatches = async () => {
      const response = await api.get('/devs/matches', {
        headers: { user },
      });

      setMatches(response.data);
    };
    loadMatches();
  }, [user]);

  useEffect(() => {
    if (!user) return;

    if (activeChat) {
      api
        .get(`/devs/${activeChat._id}/messages`, {
          headers: { user },
        })
        .then(response => setMessages(response.data));
    }

    scrollToLatestMessage();
  }, [activeChat, user]);

  useEffect(() => {
    if (!user) return;

    const socket = io(backEndUrl, {
      query: { user },
    });

    socket.on('new-message', message => {
      if (message.senderId === activeChat._id) {
        setMessages(prevMessages => [...prevMessages, message]);
        scrollToLatestMessage();
      }
    });

    return () => socket.disconnect();
  }, [user, activeChat]);

  const handleSendMessage = async () => {
    if (!user) return;

    if (!newMessage.trim()) return;

    if (isMessageBeingSent) return;

    setIsMessageBeingSent(true);

    const message = {
      senderId: user,
      receiverId: activeChat._id,
      content: newMessage,
    };

    await api.post(`/devs/matches/messages`, message);
    setMessages([...messages, message]);
    setNewMessage('');

    setIsMessageBeingSent(false);
  };

  const handleOpenChat = matchUser => {
    setActiveChat(matchUser);
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  const goBack = () => {
    history.goBack();
  };

  const scrollToLatestMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  return (
    <div className="matches-container">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaArrowLeft style={{ color: 'gray', fontSize: '1.4rem', marginRight: '15px', position: 'absolute', left: '10px' }} onClick={() => goBack()} />
        <h1 className="match-header">Meus Matchs</h1>
      </div>
      {matches.map(matchUser => (
        <div
          key={matchUser._id}
          className="match-item"
          onClick={() => handleOpenChat(matchUser)}
        >
          <img
            src={matchUser.avatar}
            alt={matchUser.name}
            width="100"
            height="100"
          />
          <div className="match-name">{matchUser.name}</div>
        </div>
      ))}

      {isChatOpen && (
        <>
        <div className="overlay" onClick={handleCloseChat}></div>
        <div className="chat-container">
          <div className="chat-header">
            <img
              src={activeChat.avatar}
              alt={activeChat.name}
              width="40"
              height="40"
            />
            <strong style={{ marginLeft: 10 }}>{activeChat.name}</strong>
            <button className="close-chat" onClick={handleCloseChat}>
              X
            </button>
          </div>
          <div className="chat-messages" ref={messagesEndRef}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.senderId === user ? 'mine' : 'theirs'
                }`}
              >
                {message.content}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          <div className="chat-input">
            <input
              type="text"
              placeholder="Digite uma mensagem"
              value={newMessage}
              onChange={e => setNewMessage(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleSendMessage()}
            />
            <button className="btn-grad" onClick={handleSendMessage} disabled={isMessageBeingSent}>Enviar</button>
          </div>
        </div>
        </>
      )}
    </div>
  );
}
