import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import './Main.css';
import api from '../../services/api';

import logo from '../../assets/logo.png';
import dislike from '../../assets/dislike.svg';
import like from '../../assets/like.svg';
import itsamatch from '../../assets/itsamatch.png';

export default function Main({ history }) {
  const [user, setUser] = useState('');
  const [users, setUsers] = useState([]);
  const [matchDev, setMatchDev] = useState(null);
  const [currentUserIndex, setCurrentUserIndex] = useState(0);

  const backEndUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3333';

  useEffect(() => {
    const loggedUser = localStorage.getItem('user');

    if (!loggedUser) {
      history.push('/');
      return;
    }

    setUser(loggedUser);
  }, []);

  useEffect(() => {
    if (!user) return;

    async function loadUsers() {
      const url = `/devs?_=${Date.now()}`;

      const response = await api.get(url, {
        headers: {
          user: user,
        },
      });

      setUsers(response.data);
    }

    loadUsers();
  }, [user]);

  useEffect(() => {
    if (!user) return;

    const socket = io(backEndUrl, {
      query: { user },
    });

    socket.on('match', dev => {
      setMatchDev(dev);
    });
  }, [user]);

  useEffect(() => {
    if (currentUserIndex >= users.length) {
      setCurrentUserIndex(0); // Reinicie o índice quando atingir o final da lista
    }
  }, [currentUserIndex, users]);  

  async function handleLike(id) {
    await api.post(`/devs/${id}/likes`, null, {
      headers: { user },
    });

    setUsers(users.filter(user => user._id !== id));
    setCurrentUserIndex(currentUserIndex + 1);
  }

  async function handleDislike(id) {
    await api.post(`/devs/${id}/dislikes`, null, {
      headers: { user },
    });

    setUsers(users.filter(user => user._id !== id));
    setCurrentUserIndex(currentUserIndex + 1);
  }

  function logout() {
    localStorage.removeItem('user');
    history.push('/');
  }

  return (
    <div className="main-container">
      <div className="main-header">
        <Link to="/">
          <img src={logo} className="logo" alt="Discaricamatch" />
        </Link>

        <Link to="/matches">
          <button className="btn-grad">Ver Matchs</button>
        </Link>
      </div>

      {users[currentUserIndex] ? (
        <>
          <div className="card">
            <img
              className="card-image"
              src={users[currentUserIndex].avatar}
              alt={users[currentUserIndex].name}
            />
            <footer>
              <strong>{users[currentUserIndex].name}</strong>
              <p>{users[currentUserIndex].bio}</p>
            </footer>
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={() => handleDislike(users[currentUserIndex]._id)}
            >
              <img src={dislike} alt="Dislike" />
            </button>
            <button
              type="button"
              onClick={() => handleLike(users[currentUserIndex]._id)}
            >
              <img src={like} alt="Like" />
            </button>
          </div>
        </>
      ) : (
        <div className="empty">Acabaram os perfis!</div>
      )}

      {matchDev && (
        <div className="match-container">
          <img src={itsamatch} alt="It's a match" />

          <img className="avatar" src={matchDev.avatar} alt="" />
          <strong>{matchDev.name}</strong>
          <p>{matchDev.bio}</p>

          <button type="button" onClick={() => setMatchDev(null)}>
            FECHAR
          </button>
        </div>
      )}


      <MdLogout style={{ color: 'grey', fontSize: '1.5rem', marginRight: '15px', position: 'fixed', bottom: 18, right: '0px' }} onClick={() => logout()} />
    </div>
  );
}
